import * as React from "react"
import Demo from "../../components/Demo"
import { UnityContext } from "react-unity-webgl"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

const unityContextDevsdataAstroFlap = new UnityContext({
  loaderUrl: "/games/astroflap-devsdata/AstroFlapDevsData.loader.js",
  dataUrl: "/games/astroflap-devsdata/AstroFlapDevsData.data",
  frameworkUrl: "/games/astroflap-devsdata/AstroFlapDevsData.framework.js",
  codeUrl: "/games/astroflap-devsdata/AstroFlapDevsData.wasm",
})

function handleOnClickFullscreen() {
  unityContextDevsdataAstroFlap.setFullscreen(true)
}

function DevsdataAstroflapGame() {
  const [progression, setProgression] = useState(0)

  useEffect(function () {
    unityContextDevsdataAstroFlap.on("progress", function (progression) {
      setProgression(progression)
    })
    unityContextDevsdataAstroFlap.on("share", function (points) {
      window
        .open(
          "https://www.facebook.com/sharer/sharer.php?u=https://oakgames.it/games/astroflap&quote=My score is: " +
            points +
            " , can you beat me?",
          "_blank"
        )
        .focus()
    })
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Zastosowanie gier w celu brandingu firmy"
        />
        <meta name="keywords" content="Gry, Gamifikacja, Astroflap" />
        <title>OakGames - Devsdata Astroflap</title>
        <meta lang="pl" />
        <meta property="og:title" content="Checkout Astroflap" />
        <meta
          property="og:description"
          content="Arcade game in which we try to avoid as many obstacles as possible by using our jetpack and performing space stunts"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oakgames.it/games/astroflap" />
        <meta
          property="og:image"
          content="https://oakgames.it/astroflap_screen.png"
        />
      </Helmet>
      <Demo
        progression={progression}
        unityContextGame={unityContextDevsdataAstroFlap}
        handleFullScreen={handleOnClickFullscreen}
      />
    </>
  )
}

export default DevsdataAstroflapGame
